import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import Banner from "../components/Home/Banner/Banner";
import SisterCompanies from "../components/Home/SectorServed/SisterCompanies";
import About from "../components/Home/About";
import HomeAction from "../components/CallAction/HomeAction";
import ActionGrey from "../components/CallAction/ActionGrey";
import Feature from "../components/Home/Feature/Feature";
import Demonstration from "../components/Home/Feature/Demonstration";

const IndexPage = () => (
  <Layout>
    <Seo title="EDXLabs | Builds, Operates and sustains Platforms for the Next Big original Idea."  description="PENTEST360, headquartered in the Kingdom of Bahrain, is a 24x7x365 Penetration testing service offered through a feature-rich, cloud-based platform. PENTEST360 was developed to deliver instant visibility during penetration testing and enables end users to view progress in real time." />
    <Banner/>
    <About/>
    <HomeAction/>
    <SisterCompanies/>
    <ActionGrey/>
    <Feature/>
    <Demonstration/>
  </Layout>
)

export default IndexPage
