import styled from "styled-components"

export const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
  }

    .container-fluid{ 
      width: 100%;
      padding: 0;
    }
    .carousel-caption{
      bottom: 50%;
      transform: translateY(50%);
    }
    video.video-player {
      opacity: 0.8;
      filter: brightness(0.5) contrast(1.8);
  }
  h3{
    color: #fff;
    font-size: 50px;
    font-weight: 900;
    font-family: 'Montserrat';
    filter: drop-shadow(1px 2px 1px black);
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
  p{
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`
