import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

const Counter = (props) => (
  <>
    <CountUp start={0} end={props.end} duration={1} suffix={props.suffix} decimals={props.decimal}
      useEasing={true} redraw={true}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
    <p className="counter-title">{props.counterTitle}</p>
    <p className="sub-title">{props.counterText}</p>
  </>
);

export default Counter;