import React from 'react'
import {CompanyWrapper} from "./SectorServer.styles"
import { Container, Col, Row } from "react-bootstrap"
import Ctm360 from "../../../images/ctm360.png"
import Mg360 from "../../../images/mg360.png"
import Dmarc360 from "../../../images/dmarc360.png"
import Pentest360 from "../../../images/pentest360.png"

import Cards from "../../UI/Cards"

function SisterCompanies() {
  return (
    <CompanyWrapper>
        <Container>
        <h2>
        OUR COMPANIES</h2>
            <Row className="row-eq-height sister_companies">
            <Col lg={3} md={3} sm={12} xs={12}>
                <Cards><a href="https://www.ctm360.com/" target="_blank"><img src={Ctm360} /></a></Cards>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                <Cards><a href="https://www.dmarc360.com/" target="_blank"><img src={Dmarc360} /></a></Cards>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                <Cards><a href="https://www.pentest360.com/" target="_blank"><img src={Pentest360} /></a></Cards>
                </Col>
            <Col lg={3} md={3} sm={12} xs={12}>
            <Cards> <a href="https://www.mg360.io/" target="_blank"><img src={Mg360} /></a></Cards>
                </Col>
               
         
            </Row>
        </Container>
    </CompanyWrapper>
  )
}

export default SisterCompanies