import styled from "styled-components"


export const CompanyWrapper = styled.div`
padding: 80px 0;
position: relative;
h2{
    font-size: 2.2em;
    color: #bc0000;
}
.sister_companies{
    padding: 30px 0;

    a{
        align-self: center;
        margin: 0;
        img{
            align-self: center;
            margin: 0; 
        }
    }
}


`