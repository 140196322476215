import React from 'react'
import {Container, Col, Row} from "react-bootstrap"
import { Wrapper } from "./About.styles"
import Logo from "../../../images/edx-labs-logo-red-black.png"
import Badge from "../../../images/aws-badge.png"
import Counter from './Counter'

const counterData = [
    {
        end: "20",
        counterTitle: "Platforms Successfully",
        counterText: "Executed and Managed",
        suffix: " +",
        decimal: "0",
    },
    {
        end: "100",
        counterTitle: "Ideas successfully",
        counterText: "Realized and Monetized",
        suffix: " %",
        decimal: "0",
    },
    {
        end: "14",
        counterTitle: "Unique Big Data Records",
        counterText: "Seamlessly Managed",
        suffix: " Billion",
        decimal: "0"
    },
    {
        end: "22",
        counterTitle: "Countries Serviced",
        counterText: "Across the World",
        suffix: "",
        decimal: "0"
    }
]
function About() {
  return (
    <Wrapper>
        <Container>
            <Row className='align-items-center'>
                <Col lg={3} md={3} sm={12} xs={12} className="logo_part">
                    <img src={Logo}></img>
                    <p><em>"Technology Company of the Year"</em>&nbsp;-&nbsp;<strong>MEED 2019</strong></p>
                    </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="description">
                    <p>
                    A global perspective on Innovation is that it is driven by a few developed economies. The Arab World is keen on being part of the Fourth Industrial Revolution(4IR) but remains to date a large consumer society. The spending is less than 1% in the region on true R&D initiatives. EDX Labs, based in the Kingdom of Bahrain focuses on thriving on R&D and building innovative platforms.
                    </p>
                    <p>
                    EDX Labs is considered as a movement towards innovation through world-class technology from the Arab region; our mission is to convert visionary ideas into fully functional platforms. EDX is an innovation hub bridging the best capabilities typically offered by software houses, VCs and incubators. We build, sustain and manage platforms.
                    </p>
                    <p>
                    EDX has a successful track record of building leading technologies. Our first 4 SaaS ventures (including CTM360, DMARC360, PENTEST360, and MG360) have successfully scaled into stand-alone entities.
                    </p>
                    </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                    <img src={Badge}></img>
                    </Col>
            </Row>
            <Row>
            {counterData.map(Item => (
          <Col lg={3} md={3} sm={6} xs={12} key={Item.counterTitle}>
       
       <div className="counter-up">
                <Counter
                  end={Item.end}
                  counterTitle={Item.counterTitle}
                  counterText={Item.counterText}
                  suffix={Item.suffix}
                  decimal={Item.decimal}
                />
              </div>

          </Col>
        ))}
            </Row>
            
        </Container>
    </Wrapper>
  )
}

export default About