import React, { useEffect } from "react";
import { Link } from "gatsby"
import {Container, Col, Row, Carousel} from "react-bootstrap"
import { BannerWrapper } from "./Banner.styles"
import { useAnimation, motion } from "framer-motion"
import Dna from "../../../images/dna.mp4"
import Bulb1 from "../../../images/bulbs.mp4"
import Bulb2 from "../../../images/bulbs2.mp4"


const Banner = () => {
    
    const controls = useAnimation()

    useEffect(() => {
      controls.start(i => ({
        opacity: 1,
        transition: { delay: i * 4 , repeat: 'Infinity', repeatType: "reverse", duration: 5, repeatDelay: 1},
        
      }))
    }, [])

    return(
        <BannerWrapper>     
                  
            <Container fluid>
                <Row>

                    <Col lg={12} md={12} sm={12} xs={12}>
                    <Carousel fade>
  <Carousel.Item>
  <video
                  className="video-player"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  autoPlay
                >
                  <source
                    src={Dna}
                    type="video/mp4"
                  />
                </video>
    <Carousel.Caption>
      <h3>Start-up to Scale-up</h3>
      <p>We mature Original Concepts into Successfull Platforms.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
  <video
                  className="video-player"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  autoPlay
                >
                  <source
                    src={Bulb1}
                    type="video/mp4"
                  />
                </video>

    <Carousel.Caption>
      <h3>We Develop World Class Platforms</h3>
      <p>Leveraging Expertise and Experience in Building Business.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
  <video
                  className="video-player"
                  height="100%"
                  width="100%"
                  loop
                  muted
                  autoPlay
                >
                  <source
                    src={Bulb2}
                    type="video/mp4"
                  />
                </video>

    <Carousel.Caption>
      <h3>Innovation is in Our DNA</h3>
      <p>Got an Idea you want to Scale?</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
                    </Col>
                </Row>
                
            </Container>
           
        </BannerWrapper>
    )
}
export default Banner;