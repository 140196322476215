import { FaCheck } from '@react-icons/all-files/fa/FaCheck'
import React from 'react'
import {Container, Col, Row} from "react-bootstrap"
import { Wrapper } from "./Feature.styles"
const data = [
    { text: "Fully Cloud-based" },
    { text: "Innovative & Original" },
    { text: "In-house R&D" },
    { text: "Built for Agility" },
    { text: "Globally Accessible" },
    { text: "Machine Learning Ready" },
    { text: "Full Stack Dev" },
    { text: "Automated Platforms" },
    { text: "Lifecycle Management" },
    { text: "Quick to Iterate" },
    { text: "Shared Services" },
    { text: "Platform Builders" }
]
function Feature() {
  return (
    <Wrapper>
        <Container>
            <Row>
                <h2>Driving growth and success of <strong>tomorrow's innovation</strong></h2>
                <p>
                EDX Labs holds extensive execution, scaling and management expertise
                </p>
            </Row>
            <Row className='feature_listing'>
                {
                    data.map((item) =>
                    <Col lg={3} md={3} sm={6} xs={12} className="feature_item">
                        <div>
                            <FaCheck/>
                            <p> {item.text} </p>
                        </div></Col>
                  )
                }
            </Row>
        </Container>
    </Wrapper>
  )
}

export default Feature