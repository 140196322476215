import styled from "styled-components"
import Concept from "../../../images/arrow-concept.png"

export const Wrapper = styled.div`
    padding: 80px 0;

    h2{
        text-transform: capitalize;
        margin-bottom: 8px;
    }
    p{
        font-size: 20px;
    }
    .feature_listing{
        padding-top: 40px;

        .feature_item{
            margin-bottom: 20px;
            div{
                box-shadow: 1px 1px 5px -2px;
                padding: 15px 15px;
                height: 100%;
                display: flex;
                align-items: center;

                p{
                    margin :0;
                    margin-left: 5px;
                }
                svg{
                    font-size: 18px;
                    color: #bc0000;
                    background: #f1f1f1;
                    border-radius: 100%;
                    padding: 2px 2px;
                }
            }
        }
    }
`
export const DemoWrapper = styled.div`
    padding-top: 20px;
    padding-bottom: 80px;
    background-image: url(${Concept});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;;
    position: relative;
}

    .circle_box{
        width: 200px;
        height: 200px;
        margin: 0 auto;
        background: #f1f1f1;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #ccc;
        position: relative;

        :before{
            content: "";
    display: block;
    position: absolute;
    bottom: -30px;
    left: 50%;
    height: 8px;
    width: 50%;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);

    transform: translate(-50%, 0);
        }
    }
    .circle_box_bigger{
        width: 380px;
        height: 380px;
        background: #f1f1f1;
        border-radius: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #ccc;
        position: relative;

        :before{
            content: "";
    display: block;
    position: absolute;
    bottom: -30px;
    left: 50%;
    height: 8px;
    width: 50%;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);

    transform: translate(-50%, 0);
        }
    }

    
`