import { Link } from 'gatsby'
import React from 'react'
import {Container, Col, Row} from "react-bootstrap"
import { GreyWrapper } from "./CallAction.styles"

function ActionGrey() {
  return (
    <GreyWrapper>
        <Container>
            <Row className='align-items-center'>
               <Col lg={8} md={8} sm={12} xs={12}>
               <h4>
               We build, scale and manage world-class technologies
                </h4>
                <p>
                Delivered through the cloud, our platforms are globally-accessible
                </p>
                   </Col>
                   <Col lg={4} md={4} sm={12} xs={12}>
                       <Link to="/contact"> Contact Us</Link>
                       </Col>

            </Row>
        </Container>
    </GreyWrapper>
  )
}

export default ActionGrey