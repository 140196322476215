import React from 'react'
import {Container, Col, Row} from "react-bootstrap"
import { Wrapper } from "./CallAction.styles"

function HomeAction() {
  return (
    <Wrapper>
        <Container>
            <Row className='align-items-center'>
               <Col lg={8} md={8} sm={12} xs={12}>
               <h4>
                EDX labs 5 years on build locally scale globally
                </h4>
                <p>
                An exclusive interview with Mirza Asrar Baig, Co-Founder, and CEO of Bahrain-based R&D company, EDX Lab
                </p>
                   </Col>
                   <Col lg={4} md={4} sm={12} xs={12}>
                       <a href="https://www.bizbahrain.com/edx-labs-5-years-on-build-locally-scale-globally/" target="_blank"> Read More</a>
                       </Col>

            </Row>
        </Container>
    </Wrapper>
  )
}

export default HomeAction