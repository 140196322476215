import styled from "styled-components"
import Img from "../../images/globe.jpeg"

export const Wrapper = styled.div`
background-image: url(${Img});
padding: 50px 0;
padding: 50px 0;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: #140001;
    background-position: 136%;
    background-attachment: initial;

    h4{
        color: #fff;
    font-size: 35px;
    text-transform: capitalize;
    }
    p{
        font-size: 20px;
    color: #f1f1f1;
    max-width: 775px;
    }
    a{
        color: #bc0000;
        background: #fff;
        padding: 10px 20px;
        font-size: 15px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: 'Montserrat';
        font-weight: 600;
        border-radius: 5px;
    }
`

export const GreyWrapper = styled.div`
padding: 50px 0;
padding: 50px 0;
    background-color: #7a7a7a;

    h4{
        color: #fff;
    font-size: 35px;
    text-transform: capitalize;
    }
    p{
        font-size: 20px;
    color: #f1f1f1;
    max-width: 775px;
    }
    a{
        color: #fff;
        background: #bc0000;
        padding: 10px 20px;
        font-size: 15px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: 'Montserrat';
        font-weight: 600;
        border-radius: 5px;
    }
`