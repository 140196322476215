import styled from "styled-components"

export const Wrapper = styled.div`
padding: 50px 0;
.logo_part{
    img{
        margin: 0;

        @media (max-width: 768px) {
            max-width: 200px;
          }
    }
    p{
        color: #777;
        font-size: 18px;
        line-height: 24px;
    }
}
.description{
    p{
        font-size: 16px;
    line-height: 24px;
    }
}
.counter-up {
    text-align: center;
    padding: 50px 0;
    span {
        font-size: 40px;
        color: rgb(172, 31, 35);
        font-weight: 800;
        font-family: Montserrat;
    }
    .counter-title {
        color: #333;
        font-weight: 600;
        margin:  0px;
        font-size: 16px;
    }
    .sub-title {
        color: rgb(0, 0, 0);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        margin: 0px;
    }
}
`