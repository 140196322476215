import React from 'react'
import {Container, Col, Row, Carousel} from "react-bootstrap"
import { DemoWrapper } from "./Feature.styles"
import Ctm360 from "../../../images/ctm-roller.png"
import Mg360 from "../../../images/mg360-roller.png"
import Dmarc360 from "../../../images/dmarc360-roller.png"
import Pentest360 from "../../../images/pentest360-roller.png"

function Demonstration() {
  return (
    <DemoWrapper>
        <Container>
            <Row className='align-items-center'>
                <Col lg={2} mg={2} sm={12} xs={12}>
                    <span className='circle_box'>Entrepreneurship</span>
                </Col>
                <Col lg={2} mg={2} sm={12} xs={12}>
                    <span className='circle_box'>Development</span>
                </Col>
                <Col lg={2} mg={2} sm={12} xs={12}>
                    <span className='circle_box'>Excellence</span>
                </Col>
                <Col lg={6} mg={6} sm={12} xs={12}>
                    <div className='circle_box_bigger'>
                    <Carousel slide controls={false} indicatorLabels={false}>
  <Carousel.Item>
  <img src={Ctm360} />  
  </Carousel.Item>
  <Carousel.Item>
  <img src={Dmarc360} />
  </Carousel.Item>
  <Carousel.Item>
  <img src={Pentest360} />
  </Carousel.Item>
  <Carousel.Item>
  <img src={Mg360} />
  </Carousel.Item>
</Carousel>
                        </div>
                </Col>
            </Row>
        </Container>
    </DemoWrapper>
  )
}

export default Demonstration